import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class StrategyPesosInformationViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.contract-savings.investments.investments_allianz';

  small_screen: null | boolean = null;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  strategies = [];

  constructor() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.strategies = this.translate('strategies');
  }

  getClassNameByBenefit = (value: string) => (value.includes('BBB') ? 'text-small' : 'text-body');
}
