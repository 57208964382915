





























































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import StrategyPesosInformationViewModel
  from '@/vue-app/view-models/components/goals-dashboard/create-goal/bucket/strategy-pesos-information-view-model';

@Component({
  name: 'StrategyPesosInformationDialog',
})
export default class StrategyPesosInformationDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  view_model = Vue.observable(new StrategyPesosInformationViewModel());

  prevStep() {
    this.closeModal();
  }

  nextStep() {
    this.closeModal();
  }

  closeModal() {
    this.synced_is_open = false;
  }
}
